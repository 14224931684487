import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Container from "./Container"
import Navbar from "./Navbar"
import Notification from "./Notification"
import Footer from "./Footer"
import SEO from "./SEO"

import styles from "./utils/layout.module.scss"

const Layout = ({ children, title, subtitle, seoTitle, path, isHome }) => {
  let pathArray = path ? path.split("/") : []

  return (
    <div className={classNames(styles["main"])}>
      <Fragment>
        <SEO title={seoTitle} />
        <Navbar path={pathArray} />
        <Notification isHome={isHome} />
        {title && (
          <Container isCentered className="pb-3">
            <h2 className="has-text-centered mt-1-mobile mb-0 has-text-primary is-size-3-mobile mx-0-mobile">
              {title}
            </h2>
            <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-5 is-size-5-mobile mx-0-mobile">
              {subtitle}
            </h4>
          </Container>
        )}
        <main className={classNames(styles["main__content"])}>{children}</main>
        <Footer />
      </Fragment>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
