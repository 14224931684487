import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Container from "layout/Container"
import { isBrowser } from "../../services/general"
import sitemap from "../../../static/data/sitemap.json"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed

  let url = ""
  if (isBrowser()) url = window.location.href

  let siteMapping = url.includes("giotrif")
    ? sitemap.find(site => site.site === "giotrif")
    : sitemap.find(site => site.site === "hope")

  let footerCode = url.includes("giotrif")
    ? "SC-PH-03891 OCTOBER 2021"
    : "SC-PH-03891 OCTOBER 2021"

  if (isBrowser())
    if (!sessionStorage.getItem("site")) footerCode = "SC-PH-03891 OCTOBER 2021"

  if (isBrowser() && !sessionStorage.getItem("site")) {
    siteMapping = sitemap.find(site => site.site === "no-site")
  }

  return (
    <footer className="footer px-1">
      <Container isCentered mobile={12} tablet={9} desktop={10}>
        <div className="columns">
          <div className="column is-8 pl-0 pr-2">
            <Link to="/">
              <Img fixed={logoLong} />
            </Link>
            <p className="help">
              MedGrocer's technology platforms optimize "medicine-as-a-service"
              for patients and companies. Its ePharmacy, corporate health
              services, and patient programs empower customers to get their
              medicines, vaccines, and medical services conveniently,
              cost-effectively, and intelligently.
            </p>
            <br />
            <p className="help">
              MedGrocer's team of over 300 people serve 700k patients and 300
              institutions directly and through partnerships with pharma
              companies.
            </p>
            <p className="help pt-1">
              © {currentYear}, MedGrocer · MedGrocer (MG Health Solutions, Inc.)
            </p>
            <p className="help pt-1">{footerCode}</p>
          </div>

          {siteMapping.mapping.map((section, index) => (
            <div
              className={classNames(section.classnames, "column is-2 pl-0")}
              key={index}
            >
              <h5 className="mt-0 has-text-primary">{section.section}</h5>
              {section.links.map(link => (
                <Link
                  to={`/${link.url}`}
                  className="help has-text-dark"
                  key={link.url}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </footer>
  )
}

export default Footer
