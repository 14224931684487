import moment from "moment"

export const isObjectEmpty = object => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const capitalize = string => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const formatNumber = number => {
  return parseFloat(number).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

export const isFutureDate = dateInQuestion => {
  let { month, date, year } = dateInQuestion
  let dateToday = moment().startOf("day")

  if (date)
    return dateToday < moment(`${month.value} ${date.value} ${year}`).toDate()
  return false
}
