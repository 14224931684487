/*
 * MedGrocer logo only
 */
import React, { useContext, useState, Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"
import { isBrowser } from "../../services/general"
import { AppContext } from "../../context/AppContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faShoppingCart,
  faTimes,
  faCapsules,
  faEnvelope,
  faQuestion,
  faFileAlt,
  faPhone,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons"

const Navbar = ({ path }) => {
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long-white.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircle: file(
        relativePath: { eq: "logos/medgrocer-square--white.png" }
      ) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const navbarItems = [
    {
      site: "giotrif",
      navigations: [
        {
          name: "Contact Us",
          link: "/giotrif/help-center#contact-us",
          icon: faPhone,
          isHiddenInDesktop: false,
        },
        {
          name: "Help Center",
          link: "/giotrif/help-center",
          icon: faQuestion,
          isHiddenInDesktop: false,
        },
        {
          name: "Terms and Conditions",
          link: "/giotrif/terms-and-conditions",
          icon: faFileAlt,
          isHiddenInDesktop: true,
        },
        {
          name: "Privacy Policy",
          link: "/giotrif/privacy-policy",
          icon: faShieldAlt,
          isHiddenInDesktop: true,
        },
      ],
    },
    {
      site: "hope",
      navigations: [
        {
          name: "Order",
          link: "/epharmacy/order",
          icon: faCapsules,
          isHiddenInDesktop: false,
        },
        {
          name: "Help Center",
          link: "/help-center",
          icon: faQuestion,
          isHiddenInDesktop: false,
        },
        {
          name: "Contact Us",
          link: "/help-center#contact-us",
          icon: faPhone,
          isHiddenInDesktop: false,
        },
        {
          name: "Terms and Conditions",
          link: "/terms-and-conditions",
          icon: faFileAlt,
          isHiddenInDesktop: true,
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy",
          icon: faShieldAlt,
          isHiddenInDesktop: true,
        },
      ],
    },
  ]

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  const { state, dispatch } = useContext(AppContext)
  const [isMenuActive, setMenuActive] = useState(false)

  const handleMenuActive = () => setMenuActive(!isMenuActive)
  const showCart = () =>
    dispatch({
      type: "SHOW_CART",
    })

  const medicinesInCart =
    state.epharmacy.medicines.length > 0
      ? state.epharmacy.medicines.length
      : null

  const epharmacyPath = path.includes("epharmacy")

  let url = ""
  if (isBrowser()) url = window.location.href

  let siteNavigations = url.includes("giotrif")
    ? navbarItems.find(site => site.site === "giotrif")
    : navbarItems.find(site => site.site === "hope")

  if (isBrowser() && !sessionStorage.getItem("site")) {
    siteNavigations.navigations = []
  }

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-primary is-flex">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Link to="/">
            <div className={classNames(styles["navbar__brandLogos"])}>
              <Img fixed={logoLong} className="is-hidden-mobile" />
              <Img fixed={logoCircle} className="is-hidden-tablet" />
            </div>
          </Link>
          <div className={styles["navbar__burgerContainer"]}>
            {epharmacyPath && (
              <a
                href="/#"
                role="button"
                className="icon mr-1"
                aria-label="menu"
                aria-expanded="false"
                onClick={showCart}
              >
                <FontAwesomeIcon icon={faShoppingCart} />
                {medicinesInCart && (
                  <span
                    className={classNames(
                      "tag is-small is-rounded is-warning",
                      styles["navbar__badge"]
                    )}
                  >
                    {medicinesInCart}
                  </span>
                )}
              </a>
            )}
            <a
              href="/#"
              role="button"
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="icon"
            >
              <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
            </a>
          </div>
        </div>
        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div className="navbar-end mr-1">
            {siteNavigations.navigations.map(item => (
              <Link
                to={item.link}
                className={item.class}
                onClick={() => setMenuActive(false)}
              >
                <span
                  className={classNames(
                    "navbar-item p-1",
                    styles["navbar__menuItem"],
                    { "is-hidden-desktop": item.isHiddenInDesktop }
                  )}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="is-hidden-desktop icon mr-1 has-text-primary"
                  />
                  {item.name}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      <div
        role="button"
        tabIndex={0}
        aria-label="Navbar Background"
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onKeyDown={() => setMenuActive(false)}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
