import React from "react"
import moment from "moment"
import { Link } from "gatsby"
import classNames from "classnames"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import {
  GATSBY_WEBSITE_URL,
  GATSBY_CONTACT_NUMBER,
  GATSBY_BSKIES_EMAIL,
} from "gatsby-env-variables"

import styles from "./utils/layout.module.scss"
import { isBrowser } from "../../services/general"

const Notification = ({ isHome }) => {
  let dateToday = moment().format("YYYY-MM-DD")

  const { loading, error, data } = useQuery(
    gql`
      query TodaysDate($holiday_date: date!, $domain: jsonb) {
        holiday(
          where: {
            holiday_date: { _eq: $holiday_date }
            domains: { _contains: $domain }
          }
        ) {
          name
          holiday_date
          short_message
        }
      }
    `,
    {
      variables: {
        holiday_date: dateToday,
        domain: GATSBY_WEBSITE_URL,
      },
    }
  )

  if (loading) return null
  if (error) console.log(error)

  let notificationMessage = ""
  // data.holiday === undefined || data.holiday.length === 0
  //   ? null
  //   : data.holiday[0].short_message

  let url = ""
  if (isBrowser()) url = window.location.href

  if (url.includes("giotrif"))
    notificationMessage = (
      <span className={classNames(styles["notificationMessage"])}>
        {notificationMessage || ""} Get answers from our{" "}
        <Link to="/giotrif/help-center">Help Center</Link> or{" "}
        <Link to="/giotrif/help-center/#contact-us">contact us</Link> for
        assistance.
      </span>
    )
  else
    notificationMessage = (
      <span className={classNames(styles["notificationMessage"])}>
        {notificationMessage || ""} Get answers from our{" "}
        <Link
          to="/help-center"
          className="has-text-weight-bold has-text-primary"
        >
          Help Center
        </Link>{" "}
        or contact us at{" "}
        <a
          href={`tel:${GATSBY_CONTACT_NUMBER}`}
          className="has-text-weight-bold has-text-primary"
        >
          {GATSBY_CONTACT_NUMBER}
        </a>
        .
      </span>
    )

  if (isHome)
    notificationMessage = (
      <div
        className={classNames(
          styles["notificationMessage"],
          "has-text-left-mobile"
        )}
      >
        <p>
          <b>
            Thank you for participating in the HOPE Program for Nintedanib
            (Ofev) and HRCT Diagnostic Subsidy.
          </b>
        </p>
        <p>
          The program will transition to an enhanced program with a new
          provider, MedCheck. <br />
          For any questions, you can reach out directly to Boehringer Ingelheim
          Philippines, Inc. at{" "}
          <a
            href={`mailto:${GATSBY_BSKIES_EMAIL}`}
            className="has-text-weight-bold has-text-primary"
          >
            {GATSBY_BSKIES_EMAIL}
          </a>
          . Thank you for letting us serve you.
        </p>
      </div>
    )

  if (notificationMessage)
    return (
      <div
        className={classNames(
          "notification py-1 is-warning",
          styles["notification"]
        )}
      >
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
